<template>
  <app-page name="Выплата" icon="icon-wallet-icon" html-class="payout">
    <template v-if="!finished">
      <div class="payout__process">
        <p>Обновление выплаты</p>
        <p>Выполняется обновление, подождите...</p>
      </div>
    </template>
    <success
      v-else
      title="Вы сделали это!"
      content="Выплата обновлена успешно"
      redirectTo="finances"
    />
  </app-page>
</template>

<script>
import axios from "axios";
import AppPage from "@/components/AppPage.vue";
import Success from "@/components/Success";

export default {
  components: { AppPage, Success },
  data() {
    return { finished: false };
  },
  async mounted() {
    try {
      await axios.post("/api/payouts/process", {
        token: this.$route.query.token,
        status: this.$route.query.status,
      });
      this.finished = true;
    } catch (e) {
      this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
    }
  },
};
</script>

<style lang="scss">
.payout__process {
  margin-bottom: 20px;
  padding: 10px 0;
  text-align: center;

  p {
    line-height: 1.5;
  }
}
</style>
